<template>
  <div class="fill" :style="containerStyle">
    <div class="body">
      <p class="body-title">
        {{ form.title }}
      </p>
      <van-progress :percentage="percentage" color="#ea5514" class="percentage" track-color="white" />
      <div v-for="(item, index) in list" :key="index">
        <!-- 当前需要显示的题目 -->
        <div v-if="item.isShow && index == page" class="body_item">
          <!-- 单选 -->
          <singleChoice v-if="item.subType == 1" :item="item" :index="index" :review="review" :type="1" :list="list" />
          <!-- 问答 -->
          <questionAnswer v-else-if="item.subType == 2" :item="item" :index="index" :review="review" />
          <!-- 多选 -->
          <multipleChoice v-else-if="item.subType == 3" :item="item" :index="index" :review="review" />
          <!-- 填空 -->
          <blanks v-else-if="item.subType == 4" :item="item" :index="index" :review="review" />
          <!-- 多选填空 -->
          <multipleBlanks v-else-if="item.subType == 5" :item="item" :index="index" :review="review" />
          <!-- 单选填空 -->
          <choiceBlanks v-else-if="item.subType == 6" :item="item" :index="index" :review="review" :type="1"
            :list="list" />
          <!-- 短填空 -->
          <smallBlank v-else-if="item.subType == 7" :item="item" :index="index" :review="review" />
          <div class="body_item_action">
            <p @click="last(index)">
              <van-icon name="arrow-left" />
              <span>上一题</span>
            </p>
            <p class="body_item_action-back" @click="back">从第一题开始</p>
            <p @click="next(index)" class="body_item_action_next" v-show="nextShow">
              <span>下一题</span>
              <van-icon name="arrow" />
            </p>
          </div>
          <div class="body_button">
            <van-button @click="close()" type="primary" color="#ffffff" class="body_button_close">
              回到首页
            </van-button>
            <van-button v-if="!review" @click="submit()" type="primary" color="linear-gradient(90deg, #FB7A08, #FE6113)"
              class="body_button_submit">
              <span v-if="form.submitButton == '02'">SUBMIT</span>
              <span v-else>提交</span>
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getQuestionnaireInfo, submitQue, saveQue } from '@/api/questionnaire.js';
import singleChoice from '../components/singleChoice';
import questionAnswer from '../components/questionAnswer';
import multipleChoice from '../components/multipleChoice';
import blanks from '../components/blanks';
import multipleBlanks from '../components/multipleBlanks';
import choiceBlanks from '../components/choiceBlanks';
import smallBlank from '../components/smallBlank';
export default {
  data() {
    return {
      review: false,
      userId: '', //用户id
      paperId: '', //试卷id
      list: [],
      form: {},
      image: {},
      page: 0 //当前页码
    };
  },
  components: {
    singleChoice,
    questionAnswer,
    multipleChoice,
    blanks,
    multipleBlanks,
    choiceBlanks,
    smallBlank
  },
  created() {
    this.userId = this.$store.getters.user.id;
    if (this.$route.query.review) {
      this.review = JSON.parse(this.$route.query.review);
    }
    this.paperId = this.$route.query.id;
    this.form = this.$store.getters.getQuestionnaireInfo;
    this.getInfo();
  },
  computed: {
    // 进度条
    percentage() {
      if (this.list.length == 0) {
        return 0;
      } else {
        return (((this.page + 1) / this.list.length) * 100).toFixed();
      }
    },
    containerStyle() {
      if (this.form.backgroundColor) {
        return { backgroundColor: this.form.backgroundColor };
      } else {
        return {}
      }

      // if (this.form.backgroundImage) {
      //   return { backgroundImage: `url('${this.form.backgroundImage}')` };
      // } else {
      //   return { backgroundColor: this.form.backgroundColor };
      // }
    },
    nextShow() {
      let flag = false
      for (let index = this.page + 1; index < this.list.length; index++) {
        if (this.list[index].isShow == true) {
          flag = true
        }
      }
      if (this.page + 1 >= this.list.length) {
        return false
      } else if (!flag) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    getInfo() {
      getQuestionnaireInfo(this.userId, this.paperId).then(res => {
        this.list = res.result;

        // 跳转到之前填写的进度
        if (!this.review) {
          for (let index = 0; index < this.list.length; index++) {
            if (this.list[index].subAnswer) {
              this.page = index;
            }
          }
        }

        //已经填写的数据复现
        for (const item of this.list) {
          if (item.subAnswer) {
            if (item.subType == 1) {
              // 单选
              if (item.options.length > item.subAnswer.charCodeAt() - 65) {
                item.subAnswer = item.subAnswer.charCodeAt() - 65;
              }
            } else if (item.subType == 3) {
              // 多选
              let arr = [];
              for (const t of item.subAnswer.split('##')) {
                if (item.options.length > t.charCodeAt() - 65) {
                  arr.push(t.charCodeAt() - 65);
                }
              }
              item.subAnswer = arr;
            } else if (item.subType == 4 || item.subType == 7) {
              // 填空和医管所填空
              let matches = item.title.match(/\^\{[^}]*\}\^/g).length;
              item.subAnswer = item.subAnswer.split('##').slice(0, matches)
            } else if (item.subType == 5) {
              // 多选填空
              let arr = item.subAnswer.split('##');
              item.subAnswer = [];
              for (const items of arr) {
                let num = items.split('#')[0].charCodeAt() - 65;
                if (item.options.length > num) {
                  item.subAnswer.push(num);
                  item.options[num].supplement = items.split('#')[1];
                }
              }
            } else if (item.subType == 6) {
              // 单选填空
              let num = item.subAnswer.split('#')[0].charCodeAt() - 65;
              if (item.options.length > num) {
                item.options[num].supplement = item.subAnswer.split('#')[1] ?? ''
                item.subAnswer = num;
              }
            }
          } else if (item.subType == 4 || item.subType == 7) {
            item.subAnswer = [];
          }
        }

        // 数据复现 直接跳过isShow赋值达到隐藏效果
        for (let index = 0; index < this.list.length; index++) {
          this.list[index].isShow = true;
          let { subAnswer } = this.list[index];
          if (
            subAnswer != null &&
            !this.review &&
            (this.list[index].subType == 1 || this.list[index].subType == 6) &&
            this.list[index].options[subAnswer].subOptionSkip
          ) {
            let end = this.list[index].options[subAnswer].subOptionSkip - 2;
            index = end;
          }
        }

        // 如果该题恰好隐藏 则跳转到上一个显示的题
        let page = this.page;
        if (!this.list[this.page].isShow) {
          for (let index = 0; index < page; index++) {
            if (this.list[index].isShow) {
              this.page = index;
            }
          }
        }
      });
    },
    // 下一题
    async next(index) {
      let page = this.page;
      const add = () => {
        if (this.page + 1 >= this.list.length) {
          this.page = page;
          return;
        }
        this.page += 1;
        if (!this.list[this.page].isShow) {
          add();
        }
      };

      let item = this.list[this.page];
      if (item.subAnswer == null || item.subAnswer.length == 0) {
        this.$toast.fail('请将试题填写完整');
        return;
      }
      await this.linSave(item)

      add();
      window.scrollTo(0, 0);
    },
    // 返回第一题
    back() {
      this.page = 0;
    },
    //上一题
    last(index) {
      const sub = () => {
        if (this.page - 0 <= 0) {
          this.$toast.fail('已经是第一题了');
          return;
        }
        this.page -= 1;
        if (!this.list[this.page].isShow) {
          sub();
        }
      };
      sub();
      window.scrollTo(0, 0);
    },
    // 处理整合数据
    dealData(item) {
      let obj = {
        userId: this.userId,
        questionnaireId: this.paperId,
        subId: item.id
      };
      if (item.subType == 1) {
        obj.score = item.options[item.subAnswer].score;
        obj.subAnswer = String.fromCharCode(65 + item.subAnswer);
      } else if (item.subType == 2) {
        obj.score = 0;
        obj.subAnswer = item.subAnswer;
      } else if (item.subType == 3) {
        if (item.subAnswer == null || item.subAnswer.length == 0) {
          return this.$toast.fail('请选择至少一项内容');
        }
        obj.score = 0;
        for (const items of item.subAnswer) {
          obj.score += item.options[items].score;
          if (obj.subAnswer == undefined) {
            obj.subAnswer = String.fromCharCode(65 + items);
          } else {
            obj.subAnswer += `##${String.fromCharCode(65 + items)}`;
          }
        }
      } else if (item.subType == 4 || item.subType == 7) {
        let matches = item.title.match(/\^\{[^}]*\}\^/g);
        if (matches.length != item.subAnswer.length) {
          return this.$toast.fail('请完整填写，如无则填0');
        }
        for (const items of item.subAnswer) {
          if (!items || items == '') {
            return this.$toast.fail('请完整填写，如无则填0');
          }
          if (obj.subAnswer == undefined) {
            obj.subAnswer = items;
          } else {
            obj.subAnswer += `##${items}`;
          }
        }
      } else if (item.subType == 5) {
        let str = '';
        for (const items of item.subAnswer) {
          if (!item.options[items].supplement && item.options[items].subOptionFill === 'true') {
            return this.$toast.fail('请把选项填写完整')
          }
          let fill = item.options[items].supplement ? '#' + item.options[items].supplement : '';
          str += `##${String.fromCharCode(items + 65)}${fill}`;
        }
        str = str.substring(2, str.length);
        obj.subAnswer = str;
      } else if (item.subType == 6) {
        let str = '';
        if (!item.options[item.subAnswer].supplement && item.options[item.subAnswer].subOptionFill === 'true') {
          return this.$toast.fail('请把选项填写完整')
        }
        let fill = item.options[item.subAnswer].supplement
          ? '#' + item.options[item.subAnswer].supplement
          : '';
        str += `##${String.fromCharCode(item.subAnswer + 65)}${fill}`;
        str = str.substring(2, str.length);
        obj.subAnswer = str;
      }
      return obj
    },
    // 每次点击下一题存储该题
    async linSave(item) {
      let obj = this.dealData(item)
      await saveQue(obj);
    },
    // 提交
    async submit() {
      let item = this.list[this.page];
      if (item.subAnswer == null || item.subAnswer.length == 0) {
        this.$toast.fail('请将试题填写完整');
        return;
      }
      await this.linSave(item)

      // 提交前处理数据
      const arr = [];
      for (const item of this.list) {
        if (item.isShow) {
          if (item.subAnswer == null || item.subAnswer.length == 0) {
            this.$toast.fail('请将试题填写完整');
            return;
          }
          let obj = this.dealData(item)
          arr.push(obj);
        }
      }
      submitQue(arr).then(res => {
        if (res.code == 200) {
          this.$toast.success('提交成功!');
          // this.close()
          this.$router.push({
            path: '/questionnaire/realName/end',
            query: this.$route.query
          });
        }
      });
    },
    //关闭
    close() {
      this.$router.push({
        path: '/questionnaire/realName/start',
        query: this.$route.query
      });
    }
  }
};
</script>
<style lang="less" scoped>
.fill {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
}

.body {
  padding: 0 64px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 2;

  // background-color: #f5fff9;
  &-title {
    margin-top: 150px;
    font-size: 64px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    // margin-top: 180px;
    // margin-bottom: 180px;
  }

  .percentage {
    margin-top: 104px;
    margin-bottom: 104px;
  }

  &_item {
    width: 100%;
    min-height: 872px;
    background: #ffffff;
    border-radius: 56px;
    padding: 79px 42px 80px 92px;
    text-align: left;
    margin-bottom: 48px;

    &_action {
      text-align: left;
      margin-top: 240px;
      font-size: 48px;

      >p {
        display: inline-block;
      }

      &-back {
        margin-left: 10%;
        background-color: #ea5514;
        color: #ffffff;
        padding: 30px;
        border-radius: 30px;
      }

      &_next {
        // margin-left: 25%;
        margin-left: 10%;
        color: #ea5514;
      }
    }
  }

  &_button {
    margin-top: 480px;
    text-align: center;
    margin-bottom: 120px;

    &_close {
      width: 320px;
      height: 128px;
      background: #ffffff;
      border: 4px solid #b5b5b6 !important;
      border-radius: 16px;
      color: #b5b5b6 !important;
    }

    &_submit {
      width: 320px;
      height: 128px;
      border-radius: 16px;
      margin-left: 115px;
    }
  }
}

::v-deep .van-button--normal {
  font-size: 48px !important;
  padding: 0;
}
</style>
